body {
  margin: 0;
  font-family: Whitney, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Whitney, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.root {
  padding: 20px;
}

::-webkit-scrollbar {
  display: none;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
